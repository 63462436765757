import React from "react";
import "./styles.css";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import videoBg from "./assets/solar-panels-roof-solar-cell.jpg";
import logo from "./assets/logo.png";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { HiOutlineMail } from "react-icons/hi";
import { FaPhoneAlt } from "react-icons/fa";

const App = () => {
  return (
    <section className="page overflow-hidden">
      <div className="overlay"></div>
      <img src={videoBg} alt="background" className="min-w-fit" />
      <div className="page_content flex flex-col gap-4">
        <div className="bg-white rounded-full p-2">
          <img src={logo} alt="logo" className="max-w-24 lg:max-w-max" />
        </div>
        <h1>Launching Soon</h1>
        <FlipClockCountdown
          to={"2024-04-12T14:27:32.635Z"}
          className="flip-clock"
          labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
          duration={0.5}
        />
        {/* <div className="flex items-center justify-center gap-10">
          <a className="cursor-pointer hover:scale-110 transition-all">
            <HiOutlineMail size={32} />
          </a>
          <a className="cursor-pointer hover:scale-110 transition-all">
            <FaPhoneAlt size={24} />
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default App;
